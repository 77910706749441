import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={
					"Trying to use an Insertion Sort On a Linked List and Failing Miserably | A Helpful Line-by-Line Code Tutorial"
				}
				subHeader={""}
				description={"Using the Insertion Sort with a Linked List."}
				keywords={[]}
				embeddedVideo={"_5_v2E0OWVs"}
				embeddedCode={
					"https://gist.githubusercontent.com/graphoarty/ce0f8c14cc78dd23780c4cb5f90bfa33/raw/dc89b3ee21e3f5ed11133a0aa8c8a0db8b73705f/gistfile1.txt"
				}
			/>
		);
	}
}

export default View;
